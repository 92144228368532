<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card
          icon="mdi-clipboard-text"
          title="Usuarios registrados"
          class="px-5 py-3"
          color="orange"
        >
          <v-data-table
            :headers="headers"
            :items="users"
            loading-text="Cargando... Por favor, espere"
            :loading="fetchingUser"
            no-data-text="No hay datos disponibles"

            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <br>
              <v-row
                justify="center"
                justify-md="end"
              >
                <v-col
                  cols="3"
                  md="1"
                >
                  <v-fab-transition>
                    <v-btn
                      color="orange"
                      fab
                      large
                      class="v-btn--example"
                      @click="dialogA = true"
                    >
                      <v-icon> mdi-account-plus </v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-col>
              </v-row>
            </template>
            <!-- Status -->
            <template v-slot:[`item.enable`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.enable"
                    color="success"
                    v-bind="attrs"
                    @click="deleteUser(item)"
                    v-on="on"
                  >
                    mdi-check
                  </v-icon>
                  <v-icon
                    v-else-if="!item.enable"
                    v-bind="attrs"
                    color="error"
                    @click="enableUser(item)"
                    v-on="on"
                  >
                    mdi-close
                  </v-icon>
                </template>
                <span>{{ item.enable ? 'Presione para desabilitar' : 'Presione para habilitar' }}</span>
              </v-tooltip>
            </template>
            <!-- Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                text
                fab
                dark
                small
                color="info"
                @click="openEditModal(item)"
              >
                <v-icon dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                v-if="item.enable"
                text
                fab
                dark
                small
                color="red"
                @click="openDeleteModal(item)"
              >
                <v-icon dark>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
      <form-dialog-add
        :dialog="dialogA"
        @close="dialogA = false"
      />
      <form-dialog-edit
        :edit-user="editUser"
        :dialog="dialogE"
        @close="dialogE = false"
      />
      <accept-dialog
        :dialog="dialogD"
        :item="deleteItem"
        @close="dialogD = false"
      />
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import pageCondicionerMixin from '@/mixins/pageCondicionerMixin'
  import { SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER } from '@/const'
  import FormDialogAdd from '../components/admin-users/FormDialogAdd.vue'
  import FormDialogEdit from '../components/admin-users/FormDialogEdit.vue'
  import AcceptDialog from '../components/admin-users/AcceptDialog.vue'
  export default {
    name: 'AdminUsersView',

    components: {
      FormDialogAdd,
      FormDialogEdit,
      AcceptDialog,
    },

    mixins: [pageCondicionerMixin([SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER])],

    data () {
      return {
        show: false,
        show2: false,
        show3: false,
        dialogA: false,
        dialogE: false,
        dialogD: false,
        isValid: false,
        isValidPass: false,
        editUser: {},
        deleteItem: {},
        headers: [
          {
            sortable: false,
            text: 'ID',
            value: 'id',
          },
          {
            sortable: false,
            text: 'Documento de identidad',
            value: 'ci',
          },
          {
            sortable: false,
            text: 'RIF',
            value: 'rif',
          },
          {
            sortable: false,
            text: 'Email',
            value: 'email',
          },
          {
            sortable: false,
            text: 'Nombres',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Apellidos',
            value: 'lastName',
          },
          {
            sortable: false,
            text: 'Estatus',
            value: 'enable',
          },
          {
            sortable: false,
            text: 'Acciones',
            value: 'actions',
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'user',
        'fetchingUser',
        'users',
        'actionsParentBusiness',
      ]),
    },

    async created () {
      await this.getUsers()
      await this.fethBusinessChilds()
      await this.fethWarehouse()
    },

    methods: {
      ...mapActions([
        'getUsers',
        'deleteUser',
        'enableUser',
        'getChildsBusiness',
        'getWarehousesByParentId',
      ]),

      async fethBusinessChilds () {
        await this.getChildsBusiness({ id: this.user.businessId || this.actionsParentBusiness })
      },

      async fethWarehouse () {
        await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      },

      openEditModal (item) {
        this.editUser = item
        this.dialogE = true
      },
      openDeleteModal (item) {
        this.deleteItem = item
        this.dialogD = true
      },
    },
  }
</script>
