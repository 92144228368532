<template>
  <v-row>
    <v-col
      v-for="(item, i) in authorizations"
      :key="i"
      cols="12"
      md="4"
    >
      <auth-item
        :authorization="item"
        :i="i"
        :edit="edit"
        @deleteRole="deleteRole"
      />
    </v-col>
  </v-row>
</template>

<script>
  import AuthItem from './AuthItem.vue'
  export default {
    name: 'AuthList',

    components: {
      AuthItem,
    },

    props: {
      authorizations: {
        type: Array,
        default: () => [],
      },
      edit: {
        type: Boolean,
        default: () => false,
      },
    },

    methods: {
      deleteRole (e) {
        // // // // // // // // // // // // // // // // // // // // // // // // // console.log('event', e)
        this.$emit('deleteAuth', e)
      },
    },
  }
</script>
