<template>
  <v-container>
    <v-row
      class="mt-4"
      justify="center"
    >
      <v-col
        cols="12"
      >
        <v-switch
          v-model="switchValue"
          color="secondary"
          label="Visualizar el inventario del almacen principal"
          @click="showInventory"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="rol"
          :items="getRoles"
          label="Roles"
          item-text="name"
          item-value="id"
          return-object
          :loading="fetchingRoles"
          :disabled="fetchingRoles"
        />
      </v-col>
      <v-col
        cols="6"
      >
        <!-- Is showed when the selected role are ADMIN and GENERAL_MANAGER -->
        <template v-if="viewTree === 1 && showTree">
          <v-treeview
            :items="itemParent"
            rounded
            hoverable
            item-disabled="locked"
            :selection-type="selectionType"
            return-object
            shaped
            open-all
          >
            <template
              v-slot:label="{ item }"
            >
              <v-tooltip
                v-if="!item.locked"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    :style="{
                      color: item.id === itemSelect.id ? 'green' : '',
                    }"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleClick(item)"
                  >
                    {{ item.name }}
                    <v-icon
                      v-if="item.id === itemSelect.id"
                      color="green"
                    >
                      mdi-check
                    </v-icon>
                  </span>
                </template>
                <span>Click para seleccionar</span>
              </v-tooltip>

              <template v-else>
                <span>
                  {{ item.name }}
                </span>
                <v-icon
                  v-if="item.id === itemSelect.id && !item.locked"
                >
                  mdi-check
                </v-icon>
              </template>
            </template>
          </v-treeview>
        </template>

        <!-- Is showed when the selected role are STORE_MANAGER, SALES_SUPERVISOR and SELLER -->
        <template v-if="viewTree === 2 && showTree">
          <v-treeview
            :items="itemChilds"
            rounded
            hoverable
            item-disabled="locked"
            :selection-type="selectionType"
            return-object
            shaped
            open-all
          >
            <template
              v-slot:label="{ item }"
            >
              <v-tooltip
                v-if="!item.locked"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    :style="{
                      color: item.id === itemSelect.id ? 'green' : item.locked ? 'grey' : '',
                    }"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleClick(item)"
                  >
                    {{ item.name }}
                    <v-icon
                      v-if="item.id === itemSelect.id"
                      color="green"
                    >
                      mdi-check
                    </v-icon>
                  </span>
                </template>
                <span :style="{ display: item.locked ? 'none' : 'block' }"> Click para seleccionar</span>
              </v-tooltip>

              <template v-else>
                <span class="grey--text">
                  {{ item.name }}
                </span>
                <v-icon
                  v-if="item.id === itemSelect.id && !item.locked"
                >
                  mdi-check
                </v-icon>
              </template>
            </template>
          </v-treeview>
        </template>

        <!-- Is showed when the selected role is WAREHOUSE_MANAGER -->
        <template v-if="viewTree === 3 && showTree">
          <v-treeview
            :items="itemWare"
            rounded
            hoverable
            item-disabled="locked"
            :selection-type="selectionType"
            return-object
            shaped
            open-all
          >
            <template
              v-slot:label="{ item }"
            >
              <v-tooltip
                v-if="!item.locked"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    :style="{
                      color: item.id === itemSelect.id ? 'green' : item.locked ? 'grey' : '',
                    }"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleClick(item)"
                  >
                    {{ item.name }}
                    <v-icon
                      v-if="item.id === itemSelect.id"
                      color="green"
                    >
                      mdi-check
                    </v-icon>
                  </span>
                </template>
                <span>Click para seleccionar</span>
              </v-tooltip>

              <template v-else>
                <span>
                  {{ item.name }}
                </span>
                <v-icon
                  v-if="item.id === itemSelect.id && !item.locked"
                >
                  mdi-check
                </v-icon>
              </template>
            </template>
          </v-treeview>
        </template>
      </v-col>
      <v-col
        cols="12"
      >
        <v-btn
          color="grey darken-2"
          @click="addAuthorization"
        >
          Anexar autorizacion
        </v-btn>
      </v-col>
      <!-- {{ authUser }} -->
      <v-col>
        <auth-list
          :authorizations="authorizations"
          edit
          @deleteAuth="deleteAuth"
        />
      </v-col>
      <!-- <v-col cols="12">
        <v-btn
          color="secondary"
          :loading="fetchingUser"
          :disabled="authorizations.length < 1"
          @click="addAuths"
        >
          Guardar &nbsp;
          <v-icon>
            mdi-content-save
          </v-icon>
        </v-btn>
      </v-col> -->
      <!-- {{ createUserResponse.id }} -->
    </v-row>
  </v-container>
</template>

<script>
  import { ADMIN, GENERAL_MANAGER, STORE_MANAGER, WAREHOUSE_MANAGER, SALES_SUPERVISOR, SELLER } from '@/const'
  import AuthList from './AuthList.vue'
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'RolesEditForm',

    components: {
      AuthList,
    },

    props: {
      dialog: {
        type: Boolean,
        default: () => false,
      },
      authUser: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        select: true,
        itemSelect: { id: null },
        rol: { id: SELLER, name: 'Vendedor' },
        lockRole: false,
        lockItems: [],
        switchValue: false,
        selectionType: 'independent',
        viewTree: 2,
        authorizations: [],
        showTree: true,
      }
    },

    computed: {
      ...mapState([
        'roles',
        'fetchingRoles',
        'childsBusiness',
        'warehouses',
        'createUserResponse',
        'fetchingUser',
        'user',
        'actionsParentBusiness',
        'parentBusiness',
      ]),

      getPrincipalWarehouseId () {
        return this.warehouses.filter(item => item.principal)[0]?.id
      },

      itemParent () {
        const businessParent = {
          id: 1,
          name: this.parentBusiness[0]?.name,
          locked: this.lockRole,
        }
        return [businessParent]
      },

      itemChilds () {
        // const stores = [
        //   { id: 2, name: 'Evertex' },
        //   { id: 3, name: 'Evercolor' },
        // ]
        // // // // // // // // // // // // // // // // // // // // // // // // // console.log('vuelvo a cargar las tiendas')

        this.tree(false)
        let stores = [...this.childsBusiness]

        stores = stores.map(item => {
          const cant = this.lockItems.filter(lock => {
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('AutorizoStores', lock, item, lock.businessId === item.id || lock.idBusiness === item.id)
            return lock.businessId === item.id || lock.idBusiness === item.id
          })
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('auvuelc', cant)
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('prev', item)
          if (cant.length) {
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log()
            item.locked = true
          } else {
            item.locked = false
          }
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('after', item)

          // item.locked = true

          return item
        })

        // // // // // // // // // // // // // // // // // // // // // // // // // console.log(stores)

        const store = {
          name: 'Tiendas',
          locked: true,
          children: stores,
        }
        this.tree(true)
        return [store]
      },

      itemWare () {
        // const warehouses = [
        //   { id: 1, name: 'Almacen 1' },
        //   { id: 2, name: 'Almacen 2' },
        // ]
        this.tree(false)
        let warehouses = [...this.warehouses]

        // // // // // // // // // // // // // // // // // // // // // // // // // console.log('warehouses1', warehouses)

        warehouses = warehouses.map(item => {
          const cant = this.lockItems.filter(lock => {
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('AutorizoWare', lock, item, lock.warehouseId === item.warehouseId || lock.idBusiness === item.warehouseId)
            return lock.warehouseId === item.warehouseId || lock.idBusiness === item.warehouseId
          })
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log(cant)
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('prev', item)
          if (cant.length) {
            item.locked = true
          } else {
            item.locked = false
          }
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('after', item)
          return item
        })

        // // // // // // // // // // // // // // // // // // // // // // // // // console.log('warehouses2', warehouses)

        const warehouse = {
          name: 'Almacenes',
          locked: true,
          children: warehouses,
        }
        this.tree(true)
        return [warehouse]
      },

      getRoles () {
        let roles = this.roles

        if (this.lockRole) {
          if (this.lockRole === 1) {
            roles = this.roles.filter(item => item.id === ADMIN || item.id === GENERAL_MANAGER)
          } else if (this.lockRole === 2) {
            roles = this.roles.filter(item => item.id === WAREHOUSE_MANAGER)
            // roles = this.dbRoles
          } else if (this.lockRole === 3) {
            roles = this.roles.filter(item => item.id === STORE_MANAGER || item.id === SELLER)
            // roles = this.dbRoles
          }
        }

        return roles.filter(item => item.id !== SALES_SUPERVISOR)
      },
    },

    watch: {
      // async switchValue (v) {
      //   if (v) {
      //     // // // // // // // // // // // // // // // // // // // // // // // // // console.log('le doy permiso con este id', this.getPrincipalWarehouseId, this.authUser)
      //     await this.updateUser({
      //       ...this.authUser,
      //       showWarehouseInventoryId: this.getPrincipalWarehouseId,
      //     })
      //   } else {
      //     // // // // // // // // // // // // // // // // // // // // // // // // // console.log('le doy permiso con este id', this.getPrincipalWarehouseId, this.authUser)
      //     await this.updateUser({
      //       ...this.authUser,
      //       showWarehouseInventoryId: null,
      //     })
      //   }

      //   // this.$emit('close')
      // },

      rol (v) {
        if (v.id === ADMIN || v.id === GENERAL_MANAGER) {
          this.viewTree = 1
        } else if (v.id === STORE_MANAGER || v.id === SALES_SUPERVISOR || v.id === SELLER) {
          this.viewTree = 2
        } else if (v.id === WAREHOUSE_MANAGER) {
          this.viewTree = 3
        }
        this.itemSelect = { id: null }
      },

      authorizations () {
        this.analyzeRoles()
      },

      async dialog (v) {
        if (v) {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Cuando se actualiza', this.authUser)
          // await this.getParentsBusinessById({ id: this.user.businessId || this.actionsParentBusiness })
          this.fillAuthorizations()
        }
      },
    },

    async created () {
      this.analyzeRoles()
      await this.getParentsBusinessById({ id: this.user.businessId || this.actionsParentBusiness })
      // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('RolesUser', this.authUser)
      this.fillAuthorizations()
    },

    methods: {
      ...mapActions([
        'assignRoles',
        'assingWarehouses',
        'getParentsBusinessById',
        'updateUser',
      ]),

      async showInventory () {
        if (this.switchValue) {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('le doy permiso con este id', this.getPrincipalWarehouseId, this.authUser)
          await this.updateUser({
            ...this.authUser,
            showWarehouseInventoryId: this.getPrincipalWarehouseId,
          })
        } else {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('le doy permiso con este id', this.getPrincipalWarehouseId, this.authUser)
          await this.updateUser({
            ...this.authUser,
            showWarehouseInventoryId: null,
          })
        }

        // this.$emit('close')
      },

      tree (v) {
        this.showTree = v
      },

      fillAuthorizations () {
        this.switchValue = !!this.authUser.showWarehouseInventoryId
        let isWarehouseManager = false

        this.authUser.authorization.forEach(item => {
          if (item.roleId === WAREHOUSE_MANAGER) {
            isWarehouseManager = true
            this.viewTree = 3
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('AdminWare')
          } else if (item.roleId === ADMIN || item.roleId === GENERAL_MANAGER) {
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Admin')
            this.viewTree = 1
          } else {
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('AdminesStore')
            this.viewTree = 2
          }
        })

        this.authorizations = isWarehouseManager ? [...this.authUser.warehouses] : [...this.authUser.authorization]
        // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Autorizoooo', this.authorizations)
      },

      getActiveValue (value) {
        // // // // // // // // // // // // // // // // // // // // // // // // // console.log(value.target)
      },

      handleClick (item) {
        if (!item.locked) {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('item', item)
          this.itemSelect = item
        }
      },

      async addAuthorization () {
        if (this.itemSelect.id) {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.rol)
          const Data = {
            idBusiness: this.itemSelect.id,
            idRole: this.rol.id,
            roleName: this.rol.name,
            itemName: this.itemSelect.name,
          }
          this.authorizations.push(Data)
          this.itemSelect = { id: null }
          // this.addAuthorization

          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('agrego este: ', Data)
          const form = {
            ...Data,
            userId: this.authUser.id,
          }
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('formulario', form.idRole === WAREHOUSE_MANAGER)

          if (form.idRole === WAREHOUSE_MANAGER) {
            // Agrega un almacen usuario
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Asigno un warehouse')
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('userParentId', this.user.id)
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('ActionparentId', this.actionsParentBusiness)
            const data = {
              ...Data,
              userId: this.authUser.id,
              parentBusiness: this.user.id || this.actionsParentBusiness,
            }
            // // // // // // // // // // // // // // // // // // // // // // // // // // console.log(data)
            await this.assignRoles(form)
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('no elimino, mas bien creo un almacen', data)
            await this.assingWarehouses(data)
          } else {
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('no elimino, mas bien creo un business', form)
            await this.assignRoles(form)
          }
        }
      },

      deleteAuth (e) {
        this.authorizations = this.authorizations.filter(item => item !== e)
      },

      analyzeRoles () {
        // // // // // // // // // // // // // // // // // // // // // // // // // console.log('analizando...', this.authorizations.length)
        this.lockItems = []
        if (this.authorizations.length < 1) {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('no hay nada')
          this.lockRole = false
          this.$emit('changeStatus', false)
        } else {
          this.$emit('changeStatus', true)
          this.authorizations.forEach((item, i) => {
            this.lockItems.push(item)
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log(i, item.idRole)
            if (item.idRole === ADMIN || item.idRole === GENERAL_MANAGER || item.roleId === ADMIN || item.roleId === GENERAL_MANAGER) {
              // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Inabilitalo')
              // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Admin, lock 1')
              this.lockRole = 1
            } else if (item.idRole === WAREHOUSE_MANAGER || item.roleId === WAREHOUSE_MANAGER || item.warehouseId) {
              // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Admin, lock 2', item.idRole || item.roleId, WAREHOUSE_MANAGER)
              // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Desabilitalo')
              this.lockRole = 2
            } else {
              // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Admin, lock 3')
              // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Admin, lock 3', item.idRole || item.roleId, WAREHOUSE_MANAGER)
              this.lockRole = 3
              // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Admin', this.lockRole)
            }
          })
        }
      },

      async addAuths () {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('AUTH', this.authorizations)
        this.authorizations.forEach(async item => {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log('agrego este: ', item)
          const form = {
            ...item,
            idRole: item.roleId,
            // userId: this.createUserResponse.id,
            userId: this.authUser.id,
            idBusiness: this.user.id || this.actionsParentBusiness,

          }
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('formulario', form.idRole === WAREHOUSE_MANAGER)

          if (form.idRole === WAREHOUSE_MANAGER) {
            // Agrega un almacen usuario
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Asigno un warehouse')
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('userParentId', this.user.id)
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log('ActionparentId', this.actionsParentBusiness)
            const data = {
              ...item,
              userId: this.authUser.id,
              // userId: this.createUserResponse.id,
              parentBusiness: this.user.id || this.actionsParentBusiness,
            }
            // // // // // // // // // // // // // // // // // // // // // // // // // console.log(data)
            await this.assignRoles(form)
            await this.assingWarehouses(data)
          } else {
            await this.assignRoles(form)
          }
        })

        // this.select = true
        // this.itemSelect = { id: null }
        // this.rol = { id: SELLER, name: 'Vendedor' }
        // this.lockRole = false
        // this.lockItems = []
        // this.switchValue = false
        // this.selectionType = 'independent'
        // this.viewTree = 2
        // this.authorizations = []
        // this.showTree = true

        this.$emit('close')
      },
    },
  }
</script>
