<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    @click:outside="close"
  >
    <v-card class="text-center">
      <v-card-title>
        Editar usuario

        <v-spacer />

        <v-icon
          aria-label="Close"
          @click="close"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <br><br>

      <v-card-text>
        <v-form
          v-model="isValid"
          @submit="e => save(e)"
        >
          <v-container class="py-0">
            <v-row>
              <v-col cols="12">
                <v-stepper
                  v-model="step"
                  non-linear
                >
                  <v-stepper-header>
                    <v-stepper-step
                      editable
                      color="orange"
                      step="1"
                    >
                      Datos Generales
                    </v-stepper-step>

                    <v-divider />

                    <v-stepper-step
                      color="orange"
                      editable
                      step="2"
                    >
                      Roles de usuario
                    </v-stepper-step>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-row
                        justify="center"
                        class="mt-4"
                      >
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-row>
                            <v-col
                              md="3"
                              class="mx-0"
                            >
                              <v-autocomplete
                                v-model="ciType"
                                :items="typesI"
                                outlined
                              />
                            </v-col>
                            <v-col
                              class="mx-0"
                            >
                              <v-text-field
                                v-model="form.ci"
                                :rules="fieldsRules"
                                required
                                class="purple-input"
                                label="Documento de identidad"
                                type="text"
                                outlined
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-row>
                            <v-col md="3">
                              <v-autocomplete
                                v-model="rifType"
                                :items="typesI"
                                outlined
                              />
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model="form.rif"
                                :rules="fieldsRules"
                                required
                                class="purple-input"
                                label="RIF"
                                type="text"
                                outlined
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="form.name"
                            :rules="fieldsRules"
                            required
                            class="purple-input"
                            label="Nombres"
                            type="text"
                            outlined
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="form.lastName"
                            :rules="fieldsRules"
                            required
                            class="purple-input"
                            label="Apellidos"
                            type="text"
                            outlined
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="form.email"
                            :rules="emailRules"
                            required
                            class="purple-input"
                            label="Email"
                            type="Email"
                            name="email"
                            outlined
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="form.password"
                            :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                            :type="show ? 'text' : 'password'"
                            name="input-10-1"
                            label="Contraseña"
                            outlined
                            @click:append="show = !show"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="form.commissions"
                            required
                            class="purple-input"
                            label="Comision por presupuesto %"
                            type="number"
                            prepend-inner-icon="mdi-percent-outline"
                            name="commission"
                            outlined
                          />
                        </v-col>
                      </v-row>

                      <v-btn
                        color="grey darken-2"
                        type="submit"
                        @click="step = 2"
                      >
                        Siguente
                      </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <!-- <v-card
                        class="mb-12"
                        color="grey lighten-1"
                        height="200px"
                      />

                      <v-btn
                        text
                        @click="step = 1"
                      >
                        Atras
                      </v-btn>
                      <v-btn
                        color="secondary"
                        :loading="fetchingUser"
                        :disabled="formDisable"
                        type="submit"
                      >
                        Guardar &nbsp;
                        <v-icon>
                          mdi-content-save
                        </v-icon>
                      </v-btn> -->
                      <roles-edit-form
                        :dialog="dialog"
                        :auth-user="editUser"
                        @close="close"
                      />
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import RolesEditForm from './RolesEditForm.vue'
  export default {
    name: 'FormDialogEdit',

    components: {
      RolesEditForm,
    },

    props: {
      dialog: Boolean,
      editUser: {
        type: Object,
        default: () => null,
      },
    },

    data () {
      return {
        step: '1',
        show: false,
        isValid: true,
        typesI: ['V', 'E', 'P'],
        ciType: 'V',
        rifType: 'V',
        userEdit: {},
        form: {
          id: '',
          ci: '',
          rif: '',
          name: '',
          lastName: '',
          email: '',
          password: '',
          commissions: 0,
        },
        fieldsRules: [
          v => !!v || 'El campo es requerido',
        ],
        emailRules: [
          v => !!v || 'El email es requerido',
          v => /.+@.+/.test(v) || 'Debe ingresar un email valido',
        ],
      }
    },

    computed: {
      ...mapState(['fetchingUser', 'roles', 'success']),
      formDisable () {
        return !(
          this.isValid &&
          this.form.ci &&
          this.form.rif &&
          this.form.name &&
          this.form.lastName &&
          this.form.commissions &&
          this.form.email) ||
          this.fetchingUser
      },
    },

    watch: {
      editUser (v) {
        this.form = { ...v }
      },
      dialog (v) {
        if (v === true) {
          this.loadFormEdit()
          // // // // // // // // // // // // // // // // // // // // // // // // console.log('prop', this.editUser)
        }
      },
      success (v) {
        // this.$emit('close', v)
      },
    },

    async created () {
      await this.getRoles()
    },

    methods: {
      ...mapActions(['getRoles', 'updateUser']),
      loadFormEdit () {
        const ci = this.editUser.ci.split('-')
        const rif = this.editUser.rif.split('-')

        this.ciType = ci[0]
        this.form.ci = ci[1]
        this.rifType = rif[0]
        this.form.rif = rif[1]
      },

      close () {
        this.step = '1'
        this.$emit('close', false)
      },

      async save (e) {
        e.preventDefault()
        this.form.ci = this.ciType + '-' + this.form.ci
        this.form.rif = this.rifType + '-' + this.form.rif
        // // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.form)
        await this.updateUser(this.form)
      },
    },
  }
</script>
