var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"color":"secondary","label":"Visualizar el inventario del almacen principal"},model:{value:(_vm.switchValue),callback:function ($$v) {_vm.switchValue=$$v},expression:"switchValue"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.getRoles,"label":"Roles","item-text":"name","item-value":"id","return-object":"","loading":_vm.fetchingRoles,"disabled":_vm.fetchingRoles},model:{value:(_vm.rol),callback:function ($$v) {_vm.rol=$$v},expression:"rol"}})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.viewTree === 1)?[_c('v-treeview',{attrs:{"items":_vm.itemParent,"rounded":"","hoverable":"","item-disabled":"locked","selection-type":_vm.selectionType,"return-object":"","shaped":"","open-all":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [(!item.locked)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({style:({
                    color: item.id === _vm.itemSelect.id ? 'green' : '',
                  }),on:{"click":function($event){return _vm.handleClick(item)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" "),(item.id === _vm.itemSelect.id)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Click para seleccionar")])]):[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),(item.id === _vm.itemSelect.id && !item.locked)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()]]}}],null,false,1680657190)})]:_vm._e(),(_vm.viewTree === 2)?[_c('v-treeview',{attrs:{"items":_vm.itemChilds,"rounded":"","hoverable":"","item-disabled":"locked","selection-type":_vm.selectionType,"return-object":"","shaped":"","open-all":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
                  var item = ref.item;
return [(!item.locked)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({style:({
                    color: item.id === _vm.itemSelect.id ? 'green' : item.locked ? 'grey' : '',
                  }),on:{"click":function($event){return _vm.handleClick(item)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" "),(item.id === _vm.itemSelect.id)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()],1)]}}],null,true)},[_c('span',{style:({ display: item.locked ? 'none' : 'block' })},[_vm._v(" Click para seleccionar")])]):[_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.id === _vm.itemSelect.id && !item.locked)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()]]}}],null,false,1739980734)})]:_vm._e(),(_vm.viewTree === 3)?[_c('v-treeview',{attrs:{"items":_vm.itemWare,"rounded":"","hoverable":"","item-disabled":"locked","selection-type":_vm.selectionType,"return-object":"","shaped":"","open-all":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
                  var item = ref.item;
return [(!item.locked)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({style:({
                    color: item.id === _vm.itemSelect.id ? 'green' : item.locked ? 'grey' : '',
                  }),on:{"click":function($event){return _vm.handleClick(item)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" "),(item.id === _vm.itemSelect.id)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Click para seleccionar")])]):[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),(item.id === _vm.itemSelect.id && !item.locked)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()]]}}],null,false,770352923)})]:_vm._e()],2),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"grey darken-2"},on:{"click":_vm.addAuthorization}},[_vm._v(" Anexar autorizacion ")])],1),_c('v-col',[_c('auth-list',{attrs:{"authorizations":_vm.authorizations},on:{"deleteAuth":_vm.deleteAuth}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary","loading":_vm.fetchingUser,"disabled":_vm.authorizations.length < 1},on:{"click":_vm.addAuths}},[_vm._v(" Guardar   "),_c('v-icon',[_vm._v(" mdi-content-save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }