<template>
  <v-sheet
    color="white"
    elevation="1"
  >
    <v-row
      justify="end"
    >
      <v-col cols="4">
        <v-btn
          fab
          dark
          small
          color="red"
          elevation="0"
          @click="deleteItem"
        >
          <v-icon>
            mdi-trash-can
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-icon
      size="100"
      :color="authorization.idRole === WAREHOUSE_MANAGER || authorization.roleId === WAREHOUSE_MANAGER || authorization.warehouseId ? 'gray' : 'secondary'"
    >
      {{ authorization.idRole === WAREHOUSE_MANAGER || authorization.roleId === WAREHOUSE_MANAGER || authorization.warehouseId ? 'mdi-warehouse' : 'mdi-store' }}
    </v-icon> <br><br>
    <span
      class="grey--text"
    >
      <span><b class="black--text">{{ authorization.roleName || authorization.role || (authorization.warehouseId && 'Gerente de Almacen') }}</b></span> en {{ authorization.itemName || authorization.business || (authorization.warehouseId && authorization.warehouseName) }}
    </span>
  </v-sheet>
</template>

<script>
  import { WAREHOUSE_MANAGER } from '@/const'
  import { mapActions } from 'vuex'
  export default {
    name: 'AuthItem',

    props: {
      authorization: {
        type: Object,
        default: () => {},
      },
      edit: {
        type: Boolean,
        default: () => false,
      },
    },

    data () {
      return {
        WAREHOUSE_MANAGER,
      }
    },

    methods: {
      ...mapActions(['deassignRoles', 'deactiveAuthWarehouse']),
      async deleteItem () {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('item', this.authorization)
        if (this.edit && (this.authorization.businessId)) {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('elimino empresa', this.authorization)
          await this.deassignRoles({ id: this.authorization.id })
          this.$emit('deleteRole', this.authorization)
        } else if (this.edit && this.authorization.warehouseId) {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('elimino almacen', this.authorization)
          this.deactiveAuthWarehouse({ id: this.authorization.id })
          this.$emit('deleteRole', this.authorization)
        } else {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('elimino nada')
          this.$emit('deleteRole', this.authorization)
        }
      },

      // deleteItem () {
      //   // // // // // // // // // // // // // // // // // // // // // // // // // console.log('item', this.authorization)
      //   this.$emit('deleteRole', this.authorization)
      // },
    },

  }
</script>
